import HttpRequest from "./httpRequest";
import { env } from "../Constant/env";
import Axios from "axios";

export enum Investor {
  Entity = 1,
  Individual = 2,
  None = 3,
}

interface AddressDetails {
  company: string;
  email: string;
  firstName: string;
  lastName: string;
  userId: number;
  phone_Number: string;
  einNumber?: string;
}

interface IndividualForm {
  email: string;
  firstName: string;
  lastName: string;
  userId: number;
}

const responseSerizer = (res: any) =>
  Object.keys(res).reduce((acc, key) => {
    const { title, placeholder, type, required, value } = res[key];
    return {
      ...acc,
      [key]: { title, placeholder, type, required, options: value },
    };
  }, {});

export const reverseLookup = (
  name: string,
  dict: { [key: string]: string }
): string =>
  (Object.entries(dict).filter(([key, value]) => value === name)[0] || [
    null,
    null,
  ])[0];

export const GetUserStatus = {
  fetchMainData: async (userId: number) => {
    const url = `${env.ENDPOINT}/v3/users/${userId}/info/`;
    return HttpRequest.get(url);
  },
};

export const Info = {
  submitUserDataToCARL: async (data: any, userId: number) => {
    const { first_name, last_name, email } = data;
    const url = `${env.ENDPOINT}/carl/submit-application`;
    const body = JSON.stringify({
      firstname: first_name,
      lastname: last_name,
      emailaddress: email,
      id: userId,
    });
    return HttpRequest.post(url, body);
  },

  GetUserDetails: async () => {
    const url = `${env.ENDPOINT}/onboarding/user_detail/`;
    return HttpRequest.get(url);
  },
  submitEntityForm: async ({
    company,
    email,
    firstName,
    lastName,
    userId,
    phone_Number,
    einNumber,
  }: AddressDetails) => {
    const url = `${env.ENDPOINT}/onboarding/personal_info/`;
    const body = JSON.stringify({
      email,
      phone_number: phone_Number,
      first_name: firstName,
      last_name: lastName,
      company_name: company,
      device_info: "Web",
      ein_number: einNumber,
    });

    return HttpRequest.post(url, body);
  },
  submitIndividualForm: async (userId: number, investorOption: number) => {
    const url = `${env.ENDPOINT}/onboarding/invester_option/`;
    const body = JSON.stringify({ userId, investorOption });
    return HttpRequest.post(url, body);
  },
  submitIndividualFormNoNUS: async ({
    email,
    firstName,
    lastName,
  }: IndividualForm) => {
    const url = `${env.ENDPOINT}/onboarding/personal_info/`;
    const body = JSON.stringify({
      email,
      first_name: firstName,
      last_name: lastName,
      device_info: "Web",
    });
    return HttpRequest.post(url, body);
  },
  submitPetitionForm: async (data: any) => {
    const url = `${env.ONBOARING_ENDPOINT}/submitPetitionForm`;
    return HttpRequest.post(url, data);
  },

  setInvestorType: async (
    investorType: number,
    mobileNumber: string,
    userId: number
  ) => {
    const url = `${env.ENDPOINT}/onboarding/investor_type/`;
    const body = JSON.stringify({ investorType, mobileNumber, userId });
    return HttpRequest.post(url, body);
  },
  get: async (type: Investor, userId: number) => {
    const url = `${env.ONBOARING_ENDPOINT}/getallpersonalinformation/${type}/${userId}`;
    const res = await fetch(url);
    if (!res.ok) throw new Error(await res.text());

    const data = await res.json();
    return responseSerizer(data);
  },
  update: async (data: any) => {
    try {
      const url = `${env.ENDPOINT}/onboarding/personal_info/`;
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!res.ok) throw new Error(await res.text());
      const message = await res.json();
      // in case if backend send ok true then we need to check success
      if (!message?.success) {
        if (typeof message?.message === "object") {
          const msg = Object.values(message?.message)[0] as string;
          throw new Error(msg);
        }
      }
      return {
        message,
        status: true,
      };
    } catch (error: any) {
      return { message: error.message, status: false };
    }
  },
};
export const KYC = {
  get: async () => {
    const url = `${env.ONBOARING_ENDPOINT}/getKycOptions`;
    const res = await fetch(url);
    if (!res.ok) throw new Error(await res.text());
    return res.json();
  },

  submit: async (data: any) => {
    const { userId, documentType, frontImage, backImage } = data;
    const url = `${env.ENDPOINT}/onboarding/kyc_info/`;
    const body = new FormData();
    body.append("userId", userId);
    body.append("documentType", documentType);
    body.append("frontImage", frontImage);
    if (backImage !== null) {
      body.append("backImage", backImage);
    }
    const jwtToken = await localStorage.getItem("token");
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${jwtToken}`,
      },
    };
    return Axios.post(url, body, config);
  },
};

export const Accreditation = {
  get: async () => {
    const url = `${env.ONBOARING_ENDPOINT}/getAccreditonOptions`;
    const res = await fetch(url);
    if (!res.ok) throw new Error(await res.text());
    return res.json();
  },
  submit: async (data: any) => {
    const { userId, optionType, image } = data;
    const body = new FormData();
    body.append("userId", `${userId}`);
    body.append("optionType", `${optionType}`);
    body.append("image", { name: "image", ...image });

    const url = `${env.ONBOARING_ENDPOINT}/submitAccreditionInfo`;

    const res = await fetch(url, { method: "POST", body });
    if (!res.ok) throw new Error(await res.text());

    return res.json();
  },
};

export const Address = {
  get: async () => {
    const url = `${env.ENDPOINT}/onboarding/address_info/`;
    const res = await fetch(url);
    if (!res.ok) throw new Error(await res.text());

    return res.json();
  },
  submit: async (data: any) => {
    const { userId, address1, address2, city, state, zipCode, country } = data;
    const url = `${env.ENDPOINT}/onboarding/address_info/`;
    const body = JSON.stringify({
      userId,
      address1,
      address2: address2 || "undifined",
      city,
      state: state || null,
      country: country || null,
      zipCode,
    });
    return HttpRequest.post(url, body);
  },
};

export const Resubmit = {
  checkRequiredDocs: async (userId: string) => {
    const url = `${env.ONBOARING_ENDPOINT}/resubmitUserDocuments/${userId}`;
    return HttpRequest.get(url);
  },
  updateUserDocuments: async (data: any) => {
    const url = `${env.ONBOARING_ENDPOINT}/updateUserDocuments`;
    const body = new FormData();
    Object.keys(data).forEach((key, index) => {
      body.append(key, data[key]);
    });
    console.log({
      body,
    });
    return HttpRequest.post(url, body);
  },
};
