import fetchIntercept from 'fetch-intercept'
import { handleHttpHeaders } from './httpHeaders'
import {onboardingApi} from '../Constant/onboardingData'
export const unregister = fetchIntercept.register({
    request: async (url, config = {}) => {
        if (url.search(onboardingApi) <  0) {
            config.headers = await handleHttpHeaders(url, config)
        }
        return [url, config]
    },

    requestError: error =>  Promise.reject(error),

    response: (response) => response,

    responseError: error => Promise.reject(error),
})
