import React from "react";
import MainStepper from "../../../Components/Stepper/MainStepper";
import Info from "../../Login/Info/Info";
import NumberVarification from "../../Login/NumberVarification";
import OTP from "../../Login/OTP";
type props = {
  title?: string;
  id?: number;
};

const arraySteps = [
  { title: "Info" },
  { title: "Number Verification" },
  { title: "OTP" },
];

const LoginStepper = () => {
  const [currentStep, setcurrentStep] = React.useState(0);
  const [isEmail, setIsEmail] = React.useState(true);
  const [existingEmail, setExistingEmail] = React.useState("");
  const handleToUpdate = (Title?: props) => {
    setcurrentStep(currentStep + 1);
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return <Info handleToUpdate={handleToUpdate} />;
      case 1:
        return (
          <NumberVarification
            handleToUpdate={handleToUpdate}
            setIsEmail={setIsEmail}
            setExistingEmail={setExistingEmail}
          />
        );
      case 2:
        return (
          <OTP
            stepLength={currentStep}
            isEmail={isEmail}
            existingEmail={existingEmail}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <MainStepper
        currentStep={currentStep}
        stepsArray={arraySteps}
        styles="display-none"
      >
        {renderComponent()}
      </MainStepper>
    </div>
  );
};

export default LoginStepper;
