import { CategoryTitle } from "../../../Constant/onboardingData";
import ArrowForward from "../../../Assets/svg/arrowforward";
import "./style.css";
import { Link } from "react-router-dom";
import { Routes } from "../../../Constant/Routes/Routes";

type props = {
  title: string;
  id: number;
};

const Documents = ({
  handleToUpdate,
}: {
  handleToUpdate: (v: props) => void;
}) => {
  return (
    <div className="container">
      <div className="header">
        <div className="mainheadertitle">
          <h4 className="title">
            Select one of the following identification documents
          </h4>
        </div>
        {CategoryTitle.map((value, index) => {
          return (
            <div
              onClick={() =>
                handleToUpdate({ title: value.title, id: value.id })
              }
              key={index}
              className="titleContainer"
            >
              <h4 className="titleText">{value.title}</h4>
              <ArrowForward />
            </div>
          );
        })}
        <div className="announcementTitleContainer">
          <Link to={Routes.HOME_SCREEN}>
            <p className="announcementTitle">
              * I do not have a copy of my identification documents available. I
              will complete my application later.
            </p>
          </Link>
        </div>
        <div>
          <p className="titleText">
            * Your ID selection must match the Investor Details you have
            provided.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Documents;
