export const NON_US_ENTITY_TITLE = "Thank you for your interest in CARL";
export const NON_US_HEADING =
  "Please fill in the form below and we will get in touch with you shortly";
export const NON_US_INDIVIDUAL_TITLE = "Non-USA phone number";
export const NON_US_INDIVIDUAL_HEADING = `We are working hard to make CARL accessible to everyone, everywhere.
Based on your phone number we need to make sure CARL is available to
you.`;
export const FOOTER_MESSAGE =
  " Under federal securities law, CARL is limited to accredited investors only.";

export const ENTITY_SPLASH_TEXT = `First, we'll get some information about you and your business. You will need 3 documents ready before starting your application:`;

export const NON_US_SPLASH_TEXT = `First, we’ll get some information about you and how you’d like to invest. 
You’ll need:`;
