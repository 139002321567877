import React from "react";
import { ENTITY_SPLASH_TEXT } from "../../Constant/Content";

const USEntitySplash = () => {
  return (
    <div>
      <p className="entitySplashText">{ENTITY_SPLASH_TEXT}</p>
      <div className="listItems">
        <div style={{ height: "100%", margin: "0px 4px" }}>
          <div className="bullet" />
        </div>
        <p className="bulletText">Your Photo ID</p>
      </div>
      <div className="listItems">
        <div style={{ height: "100%", margin: "0px 4px" }}>
          <div className="bullet" />
        </div>
        <p className="bulletText">
          An address verification document (e.g., utility bill, bank statement,
          etc.)
        </p>
      </div>
      <div className="listItems">
        <div style={{ height: "100%", margin: "0px 4px" }}>
          <div className="bullet" />
        </div>
        <p className="bulletText">
          A document showing you're authorized to represent the business
        </p>
      </div>
      <p className="getStarted">Let’s get started.</p>
    </div>
  );
};

export default USEntitySplash;
