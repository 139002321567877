import * as React from "react";
import "./button.css";

interface ButtonProps {
  disabled?: boolean;
  label: string;
  icon?: JSX.Element | null;
  className: string;
  onClick: (e: any) => Promise<void> | void
}

function Button({ label, onClick, icon, className, disabled = false }: ButtonProps) {
  return (
    <div className="button-cont-text">
      <button className={className} disabled={disabled} onClick={onClick}>
        {icon}
        {label}
      </button>
    </div>
  );
}

export default Button;
