import Button from "../../../Components/Button/Button";
import idcardLogo from "../../../Assets/Images/idcard.png";

import "./style.css";

const VerifiAddressScreen = ({
  handleToUpdate,
}: {
  handleToUpdate: () => void;
}) => {
 
  return (
    <div className="container">
      <div className="header">
        <div className="logoContainer">
          <img src={idcardLogo} alt="logo" />{" "}
        </div>

        <div className="titleContainerSuccess">
          <h4 className="title">Next, we need to verify your address</h4>
        </div>

        <Button
          label="Continue"
          className="buttonContinue"
          onClick={handleToUpdate}
        />
      </div>
    </div>
  );
};

export default VerifiAddressScreen;
