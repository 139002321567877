import React from "react";
import InputMask from "react-input-mask";
import InputField from "../Input/InputField/InputField";
import { AppContext } from "../../Context/Context";
import {
  NON_US_ENTITY_TITLE,
  NON_US_HEADING,
  FOOTER_MESSAGE,
} from "../../Constant/Content";
import "./NonUsFlow.css";
import { INVESTOR_TYPE } from "../../Constant/onboardingData";

const NonUsEntity = () => {
  const { firstName, setFirstName } = React.useContext(AppContext);
  const { lastName, setLastName } = React.useContext(AppContext);
  const { company, setCompany } = React.useContext(AppContext);
  const { email, setEmailAddress } = React.useContext(AppContext);
  const { setEinNumber } = React.useContext(AppContext);
  const { investorType } = React.useContext(AppContext);

  return (
    <div>
      <h4 className="nonUsTitle">{NON_US_ENTITY_TITLE}</h4>
      <h3 className="title">{NON_US_HEADING}</h3>
      <InputField
        type="text"
        placeholder="First Name"
        name="firstName"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        className="inputField"
      />
      <InputField
        type="text"
        placeholder="Last name"
        name="lastName"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        className="inputField"
      />
      <InputField
        type="text"
        placeholder="Company"
        name="company"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
        className="inputField"
      />
      <InputField
        type="text"
        placeholder="Email"
        name="email"
        value={email}
        onChange={(e) => setEmailAddress(e.target.value)}
        className="inputField"
      />
      {investorType === INVESTOR_TYPE.Entity ? (
        <InputMask
          mask="999999999"
          className="inputField"
          maskChar={null}
          onChange={(e) => setEinNumber(e.target.value)}
          placeholder="Business EIN (must be 9 character)"
        />
      ) : null}
      <div>
        <p style={{ marginTop: 10 }}> {FOOTER_MESSAGE}</p>
      </div>
    </div>
  );
};

export default NonUsEntity;
