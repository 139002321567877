export const handleHttpHeaders = async (url: string, config: any) => {
  const jwtToken = await localStorage.getItem("token");
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (url.search("refresh-token") >= 0) {
    const refreshToken = await localStorage.getItem("refresh_token");
    headers["X-Grant-Type"] = "refresh";
    headers["Authorization"] = `JWT ${refreshToken}`;
  } else if (
    config.method === "POST" &&
    [
      "profile-image",
      "submitKycInfo",
      "submitAccreditionInfo",
      // "submitAddressInfo",
      "updateUserDocuments",
    ].some((key) => url.search(key) >= 0)
  ) {
    headers["Accept"] = "application/json";
    headers["Content-Type"] = "multipart/form-data";
    headers["Authorization"] = `JWT ${jwtToken}`;
  } else if (jwtToken) {
    headers["Authorization"] = `JWT ${jwtToken}`;
  }

  return headers;
};
