import React from "react";
import { NON_US_SPLASH_TEXT } from "../../Constant/Content";

const NONUSSplash = ({ isUS }: any) => {
  return (
    <div>
      <p className="entitySplashText">{NON_US_SPLASH_TEXT}</p>
      <div className="listItems">
        <div style={{ height: "100%", margin: "0px 4px" }}>
          <div className="bullet" />
        </div>
        <p className="bulletText">Photo ID</p>
      </div>
      {!isUS ? (
        <div className="listItems">
          <div style={{ height: "100%", margin: "0px 4px" }}>
            <div className="bullet" />
          </div>
          <p className="bulletText">
            An address verification document (e.g., utility bill, bank
            statement, etc.)
          </p>
        </div>
      ) : (
        <div className="listItems">
          <div style={{ height: "100%", margin: "0px 4px" }}>
            <div className="bullet" />
          </div>
          <p className="bulletText">
            Personal information (e.g., name, email, dob, etc.)
          </p>
        </div>
      )}

      <p className="getStarted">Let’s get started.</p>
    </div>
  );
};

export default NONUSSplash;
