export interface IOnboardingRoutes {
  PERSONAL_INFO_SCREEN: string;
  IDENTIFICATION: string;
  ADDRESS_VERIFICATION: string;
  FINALIZE_ONBOARDING: string;
  HOME_SCREEN: string;
  MAIN: string;
}

export const OnboardingRoutes: IOnboardingRoutes = {
  PERSONAL_INFO_SCREEN: "PersonalInfo",
  IDENTIFICATION: "Identification",
  ADDRESS_VERIFICATION: "AddressVerification",
  FINALIZE_ONBOARDING: "FinalizeOnboarding",
  HOME_SCREEN: "HomeScreen",
  MAIN: "/",
};
