import React from "react";

import MainStepper from "../../../Components/Stepper/MainStepper";
import VerifiAddressScreen from "../../Onboarding/AddressScreens/VerifiAddressScreen";
import AddressInformation from "../../Onboarding/AddressScreens/AddressDetails";
import SuccessScreen from "../../../Components/SuccessScreen/SuccessScreen";
import { Routes } from "../../../Constant/Routes/Routes";
import { AppContext } from "../../../Context/Context";
import { INVESTOR_TYPE } from "../../../Constant/onboardingData";

const arraySteps = [
  { title: "Verify Address" },
  { title: "Address Details" },
  { title: "Success" },
];

const AddressStepper = () => {
  const [currentStep, setcurrentStep] = React.useState(0);
  const { firstName, email, investorType } = React.useContext(AppContext);
  const handleToUpdate = () => {
    setcurrentStep(currentStep + 1);
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return <VerifiAddressScreen handleToUpdate={handleToUpdate} />;
      case 1:
        return <AddressInformation handleToUpdate={handleToUpdate} />;
      case 2:
        return investorType === INVESTOR_TYPE.Entity ||
          investorType === INVESTOR_TYPE["Non US Entity"] ||
          investorType === INVESTOR_TYPE["Non US Individual"] ? (
          <SuccessScreen
            title="Success"
            heading={`Thank you, ${firstName}. We will email you at ${email} to schedule a time to speak.`}
            button="Visible"
          />
        ) : (
          <SuccessScreen
            title="Success"
            heading="We have recieved your address details"
            navigate={Routes.IDENTIFICATION}
          />
        );
      default:
        return null;
    }
  };

  return (
    <MainStepper currentStep={currentStep} stepsArray={arraySteps}>
      {renderComponent()}
    </MainStepper>
  );
};

export default AddressStepper;
