import React from "react";
import { Steps, Popover } from "antd";
import "./mainstepper.css";

const { Step } = Steps;

const customDot = (dot: any, { status, index }: any) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);

interface MainStepperProps {
  stepsArray: { title: string, description?: string }[];
  currentStep: number;
  children: React.ReactNode
  styles?: string
}

const MainStepper = ({ stepsArray, currentStep, children, styles }: MainStepperProps) => {
  return (
    <div className="stepperContainer">
      <Steps
        current={currentStep}
        progressDot={customDot}
        className="stepContainer"

      >
        {stepsArray.map(({ title, description }) => (
          <Step style={{ display: styles ? 'none' : 'block' }} key={currentStep} title={title} description={description} />
        ))}
      </Steps>
      {children}
    </div>
  );
}

export default MainStepper;
