import { InvestAmount } from "../../Constant/onboardingData";
import RadioButton from "../RadioButton/RadioButton";
import "./style.css";

const InvestorWorth = () => (
  <div className="radioButtonContainer">
    <div className="radioButtonTitle">
      {" "}
      <h4 className="buttonTitle">Select One of the following </h4>
    </div>
    <div>
      {InvestAmount.map(({ discripton, id }) => {
        return <RadioButton discription={discripton} id={id} key={id} />;
      })}
    </div>
  </div>
);

export default InvestorWorth;
